import { DashboardBigTitle } from "../../components/typography/Titles";
import { VSeparator } from "../../components/separators/Vertical";
import { DataTable, DataTableProps } from "../../components/table/DataTable";
import styled from "styled-components";
import { TableTextWrapper } from "./EvStationsPage/EvStationsPage";

export const NotificationsPage = () => {
  const notificationsTable: DataTableProps = {
    columns: ["Data", "Descrição", "Tipo"],
    rows: [
      [
        <TableTextWrapper>04/04/2022</TableTextWrapper>,
        <TableTextWrapper>
          Um veículo está carregando a mais de 5 horas na estação
        </TableTextWrapper>,
        <TableTextWrapper>Alerta</TableTextWrapper>,
      ],
      [
        <TableTextWrapper>04/04/2022</TableTextWrapper>,
        <TableTextWrapper>
          Um veículo está carregando a mais de 5 horas na estação
        </TableTextWrapper>,
        <TableTextWrapper>Alerta</TableTextWrapper>,
      ],
      [
        <TableTextWrapper>04/04/2022</TableTextWrapper>,
        <TableTextWrapper>
          Um veículo está carregando a mais de 5 horas na estação
        </TableTextWrapper>,
        <TableTextWrapper>Alerta</TableTextWrapper>,
      ],
      [
        <TableTextWrapper>04/04/2022</TableTextWrapper>,
        <TableTextWrapper>
          Um veículo está carregando a mais de 5 horas na estação
        </TableTextWrapper>,
        <TableTextWrapper>Alerta</TableTextWrapper>,
      ],
      [
        <TableTextWrapper>04/04/2022</TableTextWrapper>,
        <TableTextWrapper>
          Um veículo está carregando a mais de 5 horas na estação
        </TableTextWrapper>,
        <TableTextWrapper>Alerta</TableTextWrapper>,
      ],
    ],
    config: [
      {
        rowNumber: 0,
        bgColor: "#6201D0",
        color: "white",
      },
      {
        rowNumber: 1,
        bgColor: "#6201D0",
        color: "white",
      },
    ],
  };
  return (
    <>
      <DashboardBigTitle>Notificações</DashboardBigTitle>
      <VSeparator h="6.86vh" />
      <Wrapper>
        <DataTable {...notificationsTable} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 60%;
`;
