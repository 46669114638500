import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import chargers from '../assets/carregadores.png';
import logo from '../assets/logo-big.png';
import { Button } from '../components/buttons/Button';
import { LabeledInput } from '../components/labeled-input/LabeledInput';
import { useAuth } from '../hook/auth';
import { AuthenticationAPI } from '../shared/apps-backend/AuthenticationAPI';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { signin } = useAuth();

  const AuthenticationRoutesAPI = new AuthenticationAPI();
  const [LoginFormData, setLoginFormData] = React.useState({
    username: '',
    password: '',
  });

  const LoginSubmit = () => {
    try{
      if (LoginFormData.username === '' && LoginFormData.password === '') {
        alert('Campos Vazios. Preencha os campos de email e senha.');
        return;
      }
  
      AuthenticationRoutesAPI.Login(LoginFormData)
        .then((response) => {
          const { data } = response;
          if (data.access) {
            signin(data);
            localStorage.setItem('type_account',"00")
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          error.response.data.message
            ? alert(error.response.data.message)
            : alert('Erro ao logar. Tente mais tarde');
        });
    }catch(error){
      alert('Erro ao logar. Tente mais tarde');
    }
    
  };

  return (
    <Wrapper>
      <LogoContainer>
        <img src={logo} alt='logo yellot' id='logo' />
        <img src={chargers} alt='carregadores yellot' id='chargers' />
      </LogoContainer>
      <LoginForm>
        <FormTitle>Log in</FormTitle>
        <div>
          <LabeledInput
            setValue={(value) => {
              LoginFormData.username = value;
              setLoginFormData({ ...LoginFormData });
            }}
            label='Email'
            name='email'
          />
          <LabeledInput
            setValue={(value) => {
              LoginFormData.password = value;
              setLoginFormData({ ...LoginFormData });
            }}
            label='Senha'
            name='password'
            type='password'
          />
        </div>
        <Button label='Entrar' onClick={LoginSubmit} primary />
      </LoginForm>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LogoContainer = styled.div`
  padding: 3.47%;
  background-color: #6201d1;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    height: 40%;
  }

  & > #logo {
    max-width: 64%;

    @media (max-width: 768px) {
      max-width: 40%;
    }
  }

  & > #chargers {
    max-width: 98.08%;
    max-height: 60%;
    object-fit: scale-down;

    @media (max-width: 768px) {
      max-width: 40%;
    }
  }
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding: 19.34% 13%;

  @media (max-width: 768px) {
    width: 100%;
    height: 50%;
  }

  & > div input[type='text'] {
    margin-bottom: 20px;
  }

  & > button {
    margin-top: 40px;
  }
`;

const FormTitle = styled.h1`
  color: #343434;
  font-weight: 700;
  font-size: 41px;
  margin-bottom: 11.72%;

  @media (max-width: 768px) {
    margin-bottom: 6%;
    font-size: 20px;
  }
`;
