import { SyntheticEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '../../../assets/icons/dashboard/arrow-left.svg';
import { ReactComponent as CloseModal } from '../../../assets/icons/dashboard/close_modal.svg';
import { Button } from '../../../components/buttons/Button';
import { LabeledInput } from '../../../components/labeled-input/LabeledInput';
import { SelectInput } from '../../../components/selec-input/SelectInput';
import { DashboardBigTitle } from '../../../components/typography/Titles';
import { ClientsAPI } from '../../../shared/apps-backend/ClientsAPI';
import { RegisterClientModel } from '../../../shared/models/Clients/registerclient.model';

export const CustomersPageDetails = () => {
  const clientsAPI = new ClientsAPI();
  
  let { id } = useParams();
  let navigate = useNavigate();

  const [formRegisterNewClient, setFormRegisterNewClient] = useState<RegisterClientModel>({
    name: "",
    phone: "",
    email: "",
    password: "",
    is_ownerstation:'No'
  });

  let [confirmPassword,setConfirmPassword]=useState<string>("");
  const IsOwnerStationOptions=[{ id: 'Yes', description: 'Sim' },{ id: 'No', description: 'Não' }]

  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (id === undefined) return;

  }, []);

  function handleOnSubmit(event: SyntheticEvent) {
    event.preventDefault();

    if (id === '-1') {
      createNewClient();
    } 
  }

  async function createNewClient() {
    try {
      if(confirmPassword===formRegisterNewClient.password){
        await clientsAPI.RegisterNewClient(formRegisterNewClient);
        toast.success('Novo cliente foi cadastrado com sucesso');
        navigate('/dashboard/customers');
      }else{
        toast.error('As senhas não são iguais. Tente novamente');
      }
    } catch (error: any) {
      error.response.data.message ?
      toast.error(error.response.data.message) :
      toast.error('Erro ao cadastrar novo cliente. Tente mais tarde')
    }
  }


  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Title>
        <span>
          <ArrowLeft
            onClick={() => {
              navigate('/dashboard/customers');
            }}
          ></ArrowLeft>
        </span>

        {id === '-1' ? (
          <DashboardBigTitle>
           Cadastro de um novo cliente
          </DashboardBigTitle>
        ) : ''}
      </Title>

      <form onSubmit={handleOnSubmit} action='' method='POST'>
        <Fieldset>
          <LabeledInput
            setValue={(value) => {
              formRegisterNewClient.name=value;
              setFormRegisterNewClient({...formRegisterNewClient})
            }}
            value={formRegisterNewClient.name}
            type='text'
            label='Nome'
            name='name'
          />

        <LabeledInput
            setValue={(value) => {
              formRegisterNewClient.email=value
              setFormRegisterNewClient({...formRegisterNewClient})
            }}
            value={formRegisterNewClient.email}
            type='email'
            label='Email'
            name='email'
          />


        </Fieldset>

        <Fieldset>
        <LabeledInput
            setValue={(value)=>{
              formRegisterNewClient.phone=value;
              setFormRegisterNewClient({...formRegisterNewClient})
            }}
            value={formRegisterNewClient.phone}
            type='text'
            label='Telefone'
            name='phone'
          />
        
          <SelectInput
            setValue={(value) => {
              formRegisterNewClient.is_ownerstation=value;
              setFormRegisterNewClient({...formRegisterNewClient})
            }}
            value={formRegisterNewClient.is_ownerstation}
            label='O cliente é dono de alguma estação?'
            name='is_ownerstation'
            option={IsOwnerStationOptions}
          />
        </Fieldset>

        <Fieldset>

        <LabeledInput
            setValue={(value) => {
              formRegisterNewClient.password=value;
              setFormRegisterNewClient({...formRegisterNewClient})
            }}
            value={formRegisterNewClient.password}
            type='password'
            label='Senha'
            name='password'
          />

        <LabeledInput
            setValue={(value) => {
              confirmPassword=value;
              setConfirmPassword(value);

            }}
            value={confirmPassword}
            type='password'
            label='Confirmar Senha'
            name='confirmPassword'
          />

          
        </Fieldset>
        <p>
            Sua senha deve ter as seguintes características:<br/>
              * Deve ter no minímo 6 caracteres;<br/>
              * Não pode ser inteiramente numérica<br/>
        </p>

        <Fieldset>

            
          <div>
            <Button label='Salva' type='submit' primary />
          </div>

          <div>
            <Button
              label='Cancelar'
              type='button'
              onClick={() => {
                navigate('/dashboard/stations');
              }}
            />
          </div>
          {id !== '-1' ? (
            <div>
              <Button
                label='Excluir'
                type='button'
                onClick={() => {
                  openModal();
                }}
              />
            </div>
          ) : (
            false
          )}
        </Fieldset>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ModalHeader>
          <h3>Aviso!</h3>
          <button onClick={() => closeModal()}>
            <CloseModal />
          </button>
        </ModalHeader>

        <BodyModal>
          <p>Deseja mesmo apaga a estacão</p>
          <p>Caso Apaga os dados serão excluído nao tem como recuperar</p>
        </BodyModal>

        <GroupButton>
          <div>
            <Button
              label='Cancelar'
              type='button'
              onClick={() => closeModal()}
            />
          </div>
        </GroupButton>
      </Modal>
    </>
  );
};

const BodyModal = styled.header`
  p {
    margin: 1rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

const ModalHeader = styled.header`
  h3 {
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
  }
  display: flex;
  justify-content: center;
  position: relative;
  button {
    right: 0;
    position: absolute;
    cursor: pointer;
    border: none;
    background: transparent;
  }
`;

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    margin: 0.5rem;

    width: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
  }
`;

const Fieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;
  border: none;
  > div {
    margin: 0.5rem;

    width: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
