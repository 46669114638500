import React from "react";
import styled from "styled-components";

interface ButtonProps {
  label: string;
  primary?: boolean;
}

const ButtonElement = styled.button<ButtonProps>`
  background-color: ${(props) => (props.primary ? "#6201D0" : "white")};
  color: #f4f4f4;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  padding: 22px;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 45px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

export const SmallButton = ({ primary = true, ...props }: ButtonProps) => {
  return (
    <ButtonElement {...props} primary={primary}>
      {props.label}
    </ButtonElement>
  );
};
