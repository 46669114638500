import axios from "axios";
import { URLApi } from "../URLApi";

export class AdvertisementsAPI {

  private token:string;
  private URL:string;
  private URLApi:URLApi;

  constructor(){
    this.token = localStorage.getItem('token')!;
    this.URLApi=new URLApi();
    this.URL=this.URLApi.URLRoute();
  }

  RegisterAds(ads: FormData) {
    return axios.post(this.URL+'advertisements/ads/', ads, {
      headers: {
        Authorization: `Token ${this.token}`,
      },
    });
  }

  GetAllAds() {
    return axios.get(this.URL+'advertisements/ads/', {
      headers: {
        Authorization: `Token ${this.token}`,
      },
    });
  }

  UpdateAds(ads: FormData, pk:number) {
    return axios.put(this.URL+`advertisements/ads/${pk}/`, ads, {
      headers: {
        Authorization: `Token ${this.token}`,
      },
    });
  }

  DeleteAds(pk:number) {
    return axios.delete(this.URL+`advertisements/ads/${pk}/`,{
      headers: {
        Authorization: `Token ${this.token}`,
      },
    });
  }
}
