import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LogoContainer = styled.section`
  padding: 3.47%;
  background-color: #6201d1;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    height: 40%;
  }

  & > #logo {
    max-width: 64%;

    @media (max-width: 768px) {
      max-width: 40%;
    }
  }

  & > #chargers {
    max-width: 98.08%;
    max-height: 60%;
    object-fit: scale-down;

    @media (max-width: 768px) {
      max-width: 40%;
    }
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding: 19.34% 13%;
  div {
    margin: 0.5rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 50%;
  }

  & > div input[type='text'] {
    margin-bottom: 20px;
  }

  & > button {
    margin-top: 40px;
  }
  .forgot{
    font-weight: bold;
    text-align: end;
  }
`;

export const FormTitle = styled.h1`
  color: #343434;
  font-weight: 700;
  font-size: 41px;
  margin-bottom: 11.72%;

  @media (max-width: 768px) {
    margin-bottom: 6%;
    font-size: 20px;
  }
`;