import { FormEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import chargers from '../assets/carregadores.png';
import logo from '../assets/logo-big.png';
import { Button } from '../components/buttons/Button';
import { LabeledInput } from '../components/labeled-input/LabeledInput';
import { AuthenticationAPI } from '../shared/apps-backend/AuthenticationAPI';
import { FormTitle, LoginForm, LogoContainer, Wrapper } from './logins.styles';

export function ForgotPassword() {
  const navigate = useNavigate();
  const { uuid, token } = useParams();

  const authenticationRoutesAPI = new AuthenticationAPI();
  const [password, setPassword] = useState({
    password1: '',
    password2: '',
  });

  async function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (uuid === undefined || token === undefined) return;

    if (password.password1 !== password.password2) {
      toast.error('As senha nao sao iguais');
    }

    const passwordData = {
      uidb64: uuid,
      token: token,
      new_password: password.password1,
    };

    try {
      const { data } = await authenticationRoutesAPI.forgotPassword(
        passwordData
      );
      toast.success(data.data.message);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <Wrapper>
      <LogoContainer>
        <img src={logo} alt='logo yellot' id='logo' />
        <img src={chargers} alt='carregadores yellot' id='chargers' />
      </LogoContainer>
      <LoginForm onSubmit={handleOnSubmit} method='post'>
        <FormTitle>Esqueceu a senha</FormTitle>
        <div>
          <LabeledInput
            setValue={(value) => {
              password.password1 = value;
              setPassword({ ...password });
            }}
            type='password'
            label='Senha'
            name='new-password'
          />
        </div>
        <div>
          <LabeledInput
            setValue={(value) => {
              password.password2 = value;
              setPassword({ ...password });
            }}
            type='password'
            label='Confirma a senha'
            name='re-password'
          />
        </div>
        <Button type='submit' label='Salvar' primary />
      </LoginForm>
    </Wrapper>
  );
}