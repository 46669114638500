import { IconCard } from '../../components/cards/IconCard';
import { StationsInUseCard } from '../../components/cards/StationsInUseCard';
import { HSeparator } from '../../components/separators/Horizontal';
import { VSeparator } from '../../components/separators/Vertical';
import { TableTextWrapper } from './EvStationsPage/EvStationsPage';
import { Row } from '../../components/styles/Styles';
import {
  DashboardBigTitle,
  DashboardSmallTitle,
} from '../../components/typography/Titles';
import { ReactComponent as EvStationIcon } from '../../assets/icons/ev_station.svg';
import { DataTable, DataTableProps } from '../../components/table/DataTable';
import { useEffect, useState } from 'react';
import { KwProvidedChart } from '../../components/charts/KwhProvidedChart';
import breakpoints from '../../components/styles/Breakpoints';
import { ClientsAPI } from '../../shared/apps-backend/ClientsAPI';
import { ChargersAPI } from '../../shared/apps-backend/ChargersAPI';
import { ChangerStationModel } from '../../shared/models/chargers/chargers.model';
import { toast } from 'react-toastify';
export const DashboardPage = () => {
  const ClientsRoutesApi = new ClientsAPI();
  const ChargersRoutesApi = new ChargersAPI();
  const customersTableData: DataTableProps = {
    columns: ["Nome", "Email", "Telefone"],
    rows: [],
  };
  let [TableData, setTableData] = useState(customersTableData);
  let [totalChargers, setTotalChargers] = useState(0);
  let [chargersInUse, setChargersInUse] = useState(0);
  let [totalStationHours, setTotalStationHours] = useState(0);
  

  const GetAllClientsAdmin = async () => {
    try {
      let { data } = await ClientsRoutesApi.GetAllClientsAdmin();
      console.log(data)
      if (data) {
        let CustomersArray:any[] = data.data;
        let CustomersOwnerStation:any[]=CustomersArray.filter((customer:any)=>{return customer.is_ownerstation==='Não'});
        CustomersOwnerStation.map((customer: any, index: any) => {
          let { name, email, phone } = customer;
          let TableCustomerRows = [
            <TableTextWrapper key={index + 'name'}>{name}</TableTextWrapper>,
            <TableTextWrapper key={index + 'email'}>{email}</TableTextWrapper>,
            <TableTextWrapper key={index + 'phone'}>{phone}</TableTextWrapper>,
          ];
          TableData.rows.push(TableCustomerRows);
        });
        setTableData({ ...TableData });
      }
    } catch (error) {
      toast.error(`Erro ao consultar os clientes:${error}`);
    }
  };

  const GetAllChargers = async () => {
    try {
      let { data } = await ChargersRoutesApi.GetAllChargers();
      if (data) {
        let ChargersArray:ChangerStationModel[]=data.data.data;
        setTotalChargers(ChargersArray.length);
      }
    } catch (error) {
      toast.error(`Erro ao consultar as estações:${error}`);
    }
  };

  const getHoursDiff = (startDate: any, endDate: any) => {
    const msInHour = 1000 * 60 * 60;

    return Math.round(
      Math.abs(endDate.getTime() - startDate.getTime()) / msInHour
    );
  };

  const GetAllCommunicationLogs = async () => {
    try {
      let token: string = localStorage.getItem('token')!;
      let { data } = await ChargersRoutesApi.GetAllCommunicationsLogs(token);
      if (data) {
        let LogsArray: any[] = data.data;
        let FirstDate = new Date(LogsArray[0].created_at);
        let LastDate = new Date(LogsArray[LogsArray.length - 1].created_at);

        setTotalStationHours(getHoursDiff(FirstDate, LastDate));
      }
    } catch (error) {
      toast.error(`Não foi possível encontrar a quantidade de horas em uso das estações:${error}`);
    }
  };

  useEffect(() => {
    GetAllClientsAdmin();
    GetAllChargers();
    GetAllCommunicationLogs();
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <DashboardBigTitle>Dashboard</DashboardBigTitle>
      <VSeparator h='7vh' />
      <Row wrapOn={breakpoints.size.sm} wrapGap='10px'>
        <StationsInUseCard total={totalChargers} inUse={chargersInUse} />
        <HSeparator w='30px' />
        <IconCard
          icon={<EvStationIcon color='#6201D1' viewBox='0 0 45 48' />}
          children={
            <>
              <strong>{totalStationHours}</strong>
              <br />
              Horas totais em uso das estações.
            </>
          }
        />
      </Row>
      <VSeparator h='8.38vh' />
      <DashboardSmallTitle>Últimos clientes</DashboardSmallTitle>
      <VSeparator h='3.81vh' />
      <DataTable {...TableData} />
      <VSeparator h='9.60vh' />
      <KwProvidedChart />
      <VSeparator h='13vh' />
    </div>
  );
};
