import { createContext, ReactNode, useContext, useState } from 'react';

type UserType = {
  access: string;
  refresh: string;
};

interface AuthContextData {
  signin: (data: UserType) => void;
  signout: () => void;
  user: UserType;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {

  const usersInitial = {
    access: '',
    refresh: ''
  } as UserType;

  const [user, setUser] = useState<UserType>(usersInitial);

  const signin = (data: UserType) => {
    localStorage.setItem('token', data.access);
    localStorage.setItem('expiry', data.refresh);
    setUser(data);
  };

  const signout = () => {
    setUser(usersInitial);
    localStorage.removeItem('token');
    localStorage.removeItem('expiry');
  };

  return (
    <AuthContext.Provider value={{ signin, signout, user }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
