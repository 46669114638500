export class URLApi{

    private URL:string;
    private Domain:string;
    private devDomain:string;

    
    constructor(){
        this.devDomain="http://localhost:8000/"
        this.Domain="https://api.yellotmob.com.br/";
        this.URL=this.Domain;
    }

    URLRoute(){
        return this.URL;
    }
}