import {
  DashboardBigTitle,
  DashboardSmallTitle,
  H4,
} from "../../components/typography/Titles";
import { VSeparator } from "../../components/separators/Vertical";
import { LabeledInput } from "../../components/labeled-input/LabeledInput";
import { Row } from "../../components/styles/Styles";
import breakpoints from "../../components/styles/Breakpoints";
import { HSeparator } from "../../components/separators/Horizontal";
import { Button } from "../../components/buttons/Button";

export const SettingsPage = () => {
  return (
    <>
      <DashboardBigTitle>Configurações</DashboardBigTitle>
      <VSeparator h="45px" />
      <Row wrapOn={breakpoints.size.sm}>
        <LabeledInput name="station" label="Estação" />
        <HSeparator w="3.66vw" />
        <LabeledInput name="payment" label="Pagamento" />
      </Row>
      <VSeparator h="4.88vh" />
      <Row wrapOn={breakpoints.size.sm}>
        <LabeledInput name="kwhValue" label="Valor kWh" />
        <HSeparator w="3.66vw" />
        <LabeledInput name="hourValue" label="Valor hora/vaga" />
      </Row>
      <VSeparator h="45px" />
      <DashboardSmallTitle>Financeiro</DashboardSmallTitle>
      <div style={{ width: "47%" }}>
        <Row wrapOn={breakpoints.size.sm}>
          <LabeledInput name="bank" label="Banco" />
        </Row>
        <VSeparator h="4.88vh" />

        <Row>
          <LabeledInput name="bankBranch" label="Agência" />
          <HSeparator w="3.66vw" />
          <LabeledInput name="account" label="Conta" />
        </Row>
        <VSeparator h="4.88vh" />

        <Row wrapOn={breakpoints.size.sm} wrapGap="6px">
          <Button label="CANCELAR" />
          <HSeparator w="50px" />
          <Button label="SALVAR" primary />
        </Row>
      </div>
    </>
  );
};
