import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface LabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  defaultValue?: string;
  setValue?: (arg0: any) => void;
}

export const LabeledInput = ({
  defaultValue = '',
  ...props
}: LabeledInputProps) => {
  return (
    <Wrapper>
      <label htmlFor={props.name}>{props.label}</label>
      <Input
        id={props.name}
        onChange={(event) => props.setValue!(event.target.value)}
        placeholder={defaultValue}
        {...props}
      />
    </Wrapper>
  );
};

export const Input = styled.input`
  border: solid 1px #6201d1;
  border-radius: 6px;
  background-color: #f4eaff;
  flex: 1;
  padding: 5px;
  height: 50px;
  width: 100%;
`;

const Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  flex-grow: 1;

  & > label {
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
    size: 24px;
    color: #343434;
  }
`;
