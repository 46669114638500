import styled from "styled-components";

export interface DataTableProps {
  columns: string[];
  rows: React.ReactNode[][];
  config?: { rowNumber: number; bgColor?: string; color?: string }[];
  defaultBg?: string;
  onRowClick?: (rowIndex: number) => void;
}

export const DataTable = (props: DataTableProps) => {
  return (
    <TableElement
      {...(props.defaultBg?.length && { defaultBg: props.defaultBg })}
    >
      <thead>
        <tr>
          {props.columns.map((column, index) => (
            <th key={column.concat(index.toString())}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.rows.map((row, index) => (
          <tr key={index} style={{ cursor: props.onRowClick && "pointer" }}>
            {row.map((tdContent, tdIndex) => (
              <td
                {...(props.config &&
                  props.config.find((c) => c.rowNumber === index)?.rowNumber ===
                    index && {
                    style: {
                      ...(props.config.find((c) => c.rowNumber === index)
                        ?.bgColor?.length && {
                        backgroundColor: props.config.find(
                          (c) => c.rowNumber === index
                        )?.bgColor,
                      }),
                      ...(props.config.find((c) => c.rowNumber === index)?.color
                        ?.length && {
                        color: props.config.find((c) => c.rowNumber === index)
                          ?.color,
                      }),
                    },
                  })}
                key={tdIndex}
                onClick={() => props.onRowClick && props.onRowClick(index)}
              >
                {tdContent}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableElement>
  );
};

const TableElement = styled.table<Pick<DataTableProps, "defaultBg">>`
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  & > thead tr {
    height: 40px;
  }
  th {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 1rem;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 2.5vw;
      padding: 100px;
    }
  }

  td {
    background-color: ${(props) =>
      props.defaultBg?.length ? props.defaultBg : "#fff"};
    /* height: 42px; */
    max-width: 24.31vw;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    @media (max-width: 768px) {
      overflow-x: scroll;
    }
  }

  td,
  th {
    padding: 12px 10px;
    @media (max-width: 768px) {
      padding: 4px 0.5vh;
    }
  }

  tr {
    td:first-of-type,
    td:last-of-type {
      border-radius: 10px;
    }
    td:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    td:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;
