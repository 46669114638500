import { DashboardBigTitle } from "../../../components/typography/Titles";
import { DataTable, DataTableProps } from "../../../components/table/DataTable";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { VSeparator } from "../../../components/separators/Vertical";
import { SmallButton } from "../../../components/buttons/SmallButton";
import { ReactComponent as AddBtn } from '../../../assets/icons/dashboard/add_btn.svg';
import { TableTextWrapper, TitlePage } from "../EvStationsPage/EvStationsPage";
import { useEffect, useState } from "react";
import { ClientsAPI } from "../../../shared/apps-backend/ClientsAPI";
import { toast } from 'react-toastify';

export const CustomersPage = () => {
  const navigate = useNavigate();
  const clientsAPI=new ClientsAPI();
  const customersTableData: DataTableProps = {
    columns: ["Id","Nome", "Email", "Telefone",'É dono de alguma estação?'],
    rows: [],
  };

  const [customers,setCustomers]=useState<DataTableProps>(customersTableData)


  const handleClickEditCustomer=(id:number)=>{
    navigate(`/dashboard/customers/${id}`);
  }

  async function getAllClients():Promise<void>{
    try{
      const { data } = await clientsAPI.GetAllClientsAdmin();
    const ClientsData=data.data;
    ClientsData.map((client:any)=>{
      let {id,name,email,phone,is_ownerstation}=client;
      let CustomerDataRow=[
        <TableTextWrapper>{id}</TableTextWrapper>,
        <TableTextWrapper>{name}</TableTextWrapper>,
          <TableTextWrapper>{email}</TableTextWrapper>,
          <TableTextWrapper>{phone}</TableTextWrapper>,
          <TableTextWrapper>{is_ownerstation}</TableTextWrapper>,
      ]
      customers.rows.push(CustomerDataRow);
    });
    setCustomers({...customers});
    }catch(error){
      toast.error(`Erro ao consultar os clientes:${error}`)
    }
    

  }

  useEffect(() => {
    getAllClients();
  }, []);


  return (
    <>
    <TitlePage>
    <DashboardBigTitle>Clientes</DashboardBigTitle>
      <ButtonAction onClick={() => handleClickEditCustomer(-1)}>
          <AddBtn></AddBtn>
        </ButtonAction>
    </TitlePage>
      <VSeparator h="9.76vh" />
      <DataTable {...customers} />
      <VSeparator h="3.51vh" />
      <SmallButton label="EXPORTAR" />
    </>
  );


};

export const ButtonAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 1s;
  :hover {
    filter: brightness(0.7);
  }
`;
