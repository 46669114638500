import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  primary?: boolean;
  onClick?: () => void;
}

const ButtonElement = styled.button<ButtonProps>`
  background-color: ${(props) => (props.primary ? '#fdc801' : 'white')};
  
  color: #6201d1;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;

  letter-spacing: 0px;
  text-align: center;

  border-radius: 15px;
  border: none;
  padding: 22px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.35);

`;

export const Button = ({ primary, ...props }: ButtonProps) => {
  return (
    <ButtonElement {...props} primary={primary}>
      {props.label}
    </ButtonElement>
  );
};
