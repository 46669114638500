import { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../../components/header/Header";
import { NavBar } from "../../components/navbar/NavBar";
import { VSeparator } from "../../components/separators/Vertical";
import breakpoints from "../../components/styles/Breakpoints";
import { Row } from "../../components/styles/Styles";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat.svg";
import logoFooter from "../../assets/logo-footer.png";

export function DashboardLayout({ displayNavbar }: { displayNavbar: boolean }) {
  const [displayNavbarByMenuClick, setDisplayNavbarByMenuClick] =
    useState(displayNavbar);

  return (
    <>
      <Header
        menuCickHanlder={() => {
          setDisplayNavbarByMenuClick(!displayNavbarByMenuClick);
        }}
      />
      <Container>
        {(displayNavbar || displayNavbarByMenuClick) && (
          <NavBar iconClicked={() => setDisplayNavbarByMenuClick(false)} />
        )}

        <DashboardWrapper hideMargin={!displayNavbar}>
          <main>
            <Outlet />
          </main>
          <Footer>
            <ChatIconWrapper>
              <ChatIcon />
            </ChatIconWrapper>
            <div className="separator"></div>
            <VSeparator h="20px" />
            <Row
              style={{
                justifyContent: "space-between",
              }}
              wrapOn={breakpoints.size.sm}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <span id="contact">
                  Para suporte entre em contato com ti@yellot.com.br
                </span>
                <span id="copywright">
                  Todos os direitos reservados © 2022 Yellot
                </span>
              </div>
              <img src={logoFooter} alt="logo yellot" />
            </Row>
          </Footer>
        </DashboardWrapper>
      </Container>
    </>
  );
}

const DashboardWrapper = styled.div<{ hideMargin?: boolean }>`
  padding: 4.88% 5.56%;
  margin-left: ${({ hideMargin }) =>
    hideMargin ? 0 : "var(--navbar-width)"}; /* navbar width */
  background-color: #f4f4f4;
  width: 100%;
  /* height: 100%; */
  min-height: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */

  .separator {
    height: 5px;
    background-color: #6201d1;
  }

  #footer {
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 90vh;
`;

const Footer = styled.footer`
  width: 100%;
  bottom: 0;
  position: relative;
  margin-top: 16.77vh;
  img {
    max-height: calc(8vh);
  }

  #contact {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  #copywright {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #6201d1;
  }
`;

const ChatIconWrapper = styled.div`
  position: absolute;
  top: -15.8vh;
  right: 0;

  svg {
    max-width: 90px;

    @media (max-width: 768px) {
      max-width: 13.02vw;
    }
  }
`;
