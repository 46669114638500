import * as React from "react";
import styled from "styled-components";
import { ReactComponent as BarChartIcon } from "../../assets/icons/bar_chart.svg";
import { ReactComponent as EvStationIcon } from "../../assets/icons/ev_station.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/person.svg";
import { ReactComponent as LocalAtmIcon } from "../../assets/icons/local_atm.svg";
import { ReactComponent as RecordVoiceIcon } from "../../assets/icons/record_voice_over.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { NavLink } from "react-router-dom";

export const NavBar = (props: { iconClicked?: () => void }) => {
  const NavLinkWrapper = (wrapperProps: {
    children: React.ReactNode;
    to: string;
  }) => (
    <NavLink to={wrapperProps.to} onClick={props.iconClicked}>
      {wrapperProps.children}
    </NavLink>
  );

  return (
    <NavBarElement>
      <IconsColumn>
        <NavLinkWrapper to="/dashboard">
          <MenuIcon icon={<BarChartIcon />} label="Dashboard" />
        </NavLinkWrapper>
        <NavLinkWrapper to="stations">
          <MenuIcon
            icon={<EvStationIcon width="36" height="36" viewBox="-5 0 46 52" />}
            label="Estações E.V."
          />
        </NavLinkWrapper>
        <NavLinkWrapper to="customers">
          <MenuIcon icon={<PersonIcon />} label="Clientes" />
        </NavLinkWrapper>
        <NavLinkWrapper to="payments">
          <MenuIcon icon={<LocalAtmIcon />} label="Pagamentos" />
        </NavLinkWrapper>
        <NavLinkWrapper to="ads">
          <MenuIcon icon={<RecordVoiceIcon />} label="Anúncios" />
        </NavLinkWrapper>
        <NavLinkWrapper to="settings">
          <MenuIcon icon={<SettingsIcon />} label="Config." />
        </NavLinkWrapper>
        <NavLinkWrapper to="/">
          <MenuIcon icon={<LogoutIcon />} label="Sair" />
        </NavLinkWrapper>
      </IconsColumn>
    </NavBarElement>
  );
};

const MenuIcon = ({
  label,
  icon,
}: {
  label: string;
  icon: React.ReactElement;
}) => (
  <MenuIconElement>
    {icon}
    <span>{label}</span>
  </MenuIconElement>
);

const NavBarElement = styled.nav`
  width: var(--navbar-width);
  position: fixed;
  left: 0;
  height: 100vh;
  padding: 1% 2.8%;
  /* padding-right: 0; */
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  z-index: 1;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const IconsColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
`;
const MenuIconElement = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #f4f4f4;
  font-weight: 400;
  span {
    font-size: 3.59;
  }

  cursor: pointer;

  & > a {
    text-decoration: none;
  }
`;
