import { ApexOptions } from "apexcharts";
import { ChartWrapper } from "./ChartWrapper";
import Chart from "react-apexcharts";

export const KwProvidedChart = () => {
  const chartData: ApexOptions = {
    chart: {
      id: "kwh-provided-chart",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Quantidade kWh fornecido",
      align: "left",
      style: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "24px",
        color: "#6201d0",
      },
    },
    // colors: ["red"],
    fill: {
      colors: ["#D9C2F4"],
      opacity: 0.8,
      type: "solid",
    },
    stroke: {
      width: 2,
      show: true,
      colors: ["#6201D1"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: Array.from(Array(31), (_, x) => x + 1),
    },
    series: [
      {
        name: "kWh",
        data: [...Array.from(Array(31), () => Math.round(Math.random() * 350))],
      },
    ],
  };

  return (
    <ChartWrapper>
      <Chart
        options={chartData}
        series={chartData.series}
        type="area"
        width="100%"
        height={348}
      />
    </ChartWrapper>
  );
};
