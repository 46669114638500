import { DashboardBigTitle } from "../../components/typography/Titles";
import { VSeparator } from "../../components/separators/Vertical";
import { Row } from "../../components/styles/Styles";
import { IconCard } from "../../components/cards/IconCard";
import { ReactComponent as LocalAtmIcon } from "../../assets/icons/dashboard/local_atm.svg";
import { ReactComponent as CarIcon } from "../../assets/icons/dashboard/car.svg";
import { ReactComponent as EvStationIcon } from "../../assets/icons/dashboard/ev_station.svg";
import styled from "styled-components";
import { DataTable, DataTableProps } from "../../components/table/DataTable";
import { ReactComponent as OKIcon } from "../../assets/icons/dashboard/check_circle.svg";
import { IncomeChart } from "../../components/charts/IncomeChart";
import breakpoints from "../../components/styles/Breakpoints";

export const PaymentsPage = () => {
  const paymentsTable: DataTableProps = {
    columns: ["Valor (R$)", "Cliente", "Status", "Telefone"],
    rows: [
      [
        "R$ 5.249,88",
        "Luiz Henrique Sobrenomes",
        <OKIcon />,
        "(62) 99999-9999",
      ],
      [
        "R$ 5.249,88",
        "Luiz Henrique Sobrenomes",
        <OKIcon />,
        "(62) 99999-9999",
      ],
      [
        "R$ 5.249,88",
        "Luiz Henrique Sobrenomes",
        <OKIcon />,
        "(62) 99999-9999",
      ],
      [
        "R$ 5.249,88",
        "Luiz Henrique Sobrenomes",
        <OKIcon />,
        "(62) 99999-9999",
      ],
      [
        "R$ 5.249,88",
        "Luiz Henrique Sobrenomes",
        <OKIcon />,
        "(62) 99999-9999",
      ],
    ],
  };
  return (
    <>
      <DashboardBigTitle>Pagamentos</DashboardBigTitle>
      <VSeparator h="9.76vh" />
      <Row
        style={{ justifyContent: "space-between" }}
        wrapOn="800px"
        wrapGap="10px"
      >
        <IconCard icon={<LocalAtmIcon color=" #6201D1" />}>
          <CardText text="Total ganho R$ 422.294.444,42" />
        </IconCard>
        <IconCard icon={<CarIcon />}>
          <CardText text="Total ganho vaga R$ 1.492.689,42" />
        </IconCard>
        <IconCard icon={<EvStationIcon />}>
          <CardText text="Total ganho E.Vs R$ 40.736.755,00" />
        </IconCard>
        <IconCard icon={<LocalAtmIcon color=" #6201D1" />}>
          <CardText text="Ganho médio R$/kWh R$ 892,10" />
        </IconCard>
      </Row>
      <VSeparator h="7.62vh" />
      <IncomeChart />
      <VSeparator h="7.62vh" />
      <DataTable {...paymentsTable} />
    </>
  );
};

const CardText = ({ text }: { text: string }) => (
  <TextWrapper>{text}</TextWrapper>
);

const TextWrapper = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
`;
