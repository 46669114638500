import 'react-toastify/dist/ReactToastify.css';

import Modal from 'react-modal';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import {useDisplayNavbar} from './components/styles/useResize';
import {AuthProvider} from './hook/auth';
import {AdsPage} from './pages/dashboard/AdsPage';
import {CustomersPage} from './pages/dashboard/CustomersPage/CustomersPage';
import {CustomersPageDetails} from './pages/dashboard/CustomersPage/CustomersPageDetails';
import {DashboardLayout} from './pages/dashboard/DashboardLayout';
import {DashboardPage} from './pages/dashboard/DashboardPage';
import {EvStationPage} from './pages/dashboard/EvStationsPage/EvStationsPage';
import {EvStationsPageDetails} from './pages/dashboard/EvStationsPage/EvStationsPageDetails';
import {NotificationsPage} from './pages/dashboard/NotificationsPage';
import {PaymentsPage} from './pages/dashboard/PaymentsPage';
import {SettingsPage} from './pages/dashboard/SettingsPage';
import {LoginPage} from './pages/Login';
import {ForgotPassword} from "./pages/forgetPassword";

Modal.setAppElement('#root');

function App() {
    const displayNavbar = useDisplayNavbar();
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path='/' element={<LoginPage/>}/>
                    <Route path='forgot/:uuid/:token' element={<ForgotPassword/>}/>
                    <Route
                        path='dashboard'
                        element={<DashboardLayout displayNavbar={displayNavbar}/>}
                    >
                        <Route index element={<DashboardPage/>}/>
                        <Route path='stations' element={<EvStationPage/>}/>
                        <Route path='stations/:id' element={<EvStationsPageDetails/>}/>
                        <Route path='customers' element={<CustomersPage/>}/>
                        <Route path='customers/:id' element={<CustomersPageDetails/>}/>
                        <Route path='payments' element={<PaymentsPage/>}/>
                        <Route path='ads' element={<AdsPage/>}/>
                        <Route path='settings' element={<SettingsPage/>}/>
                        <Route path='notifications' element={<NotificationsPage/>}/>
                    </Route>
                </Routes>
            </Router>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
            />
        </AuthProvider>
    );
}

export default App;
