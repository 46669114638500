import { SyntheticEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '../../../assets/icons/dashboard/arrow-left.svg';
import { ReactComponent as CloseModal } from '../../../assets/icons/dashboard/close_modal.svg';
import { Button } from '../../../components/buttons/Button';
import { LabeledInput } from '../../../components/labeled-input/LabeledInput';
import { SelectInput } from '../../../components/selec-input/SelectInput';
import { DashboardBigTitle } from '../../../components/typography/Titles';
import {
  BaseAPI,
  CityType,
  StateType,
} from '../../../shared/apps-backend/BaseAPI';
import {
  ChargersAPI,
} from '../../../shared/apps-backend/ChargersAPI';
import { ClientsAPI } from '../../../shared/apps-backend/ClientsAPI';

import { CreateChangerStationModel,StationRetrieverModel } from '../../../shared/models/chargers/chargers.model';

export const EvStationsPageDetails = () => {
  const chargersAPI = new ChargersAPI();
  const baseAPI = new BaseAPI();
  const clientsAPI=new ClientsAPI();
  let { id } = useParams();
  let navigate = useNavigate();


  const initialValeu: CreateChangerStationModel  = {
    id: -1,
    address: {
      id:-1,
      city_id: -1,
      cep: '',
      address: '',
      city: {
        state_id: -1,
        name: '',
      },
    },
    status: 1,
    name: '',
    owner_station_id:-1
  };

  const [values, setValues] =
    useState<CreateChangerStationModel >(initialValeu);

  const [state, setState] = useState<StateType[]>([]);
  const [city, setCity] = useState<CityType[]>([]);

  const [ownerStationOptions,setOwnerStationsOptions]=useState<any[]>([])

  const [modalIsOpen, setIsOpen] = useState(false);

  const status = [
    { id: 1, description: 'Ativa' },
    { id: 2, description: 'Desativada' },
  ];

  useEffect(() => {
    if (id === undefined) return;

    if (id !== '-1') {
      GetRetrieverChargers(parseInt(id));
    }
    getAllState();
    GetAllOwnerStation();
  }, []);

  function handleOnSubmit(event: SyntheticEvent) {
    event.preventDefault();

    if (id === '-1') {
      createChargers();
    } else {
      updateChargers();
    }
  }

  async function createChargers() {
    try {
      await chargersAPI.CreateChargers(values);
      toast.success('Nova estacão criada com sucesso');
      navigate('/dashboard/stations');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  }

  async function updateChargers() {
    try {
      await chargersAPI.UpdateChargers(values);
      toast.success('A estacão atualizada com sucesso');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  }

  async function deleteStation(id: number,address_id:number) {
    try {
      await chargersAPI.DeleteChargers(id);
      toast.success('A estacão deleta com sucesso');
      closeModal();
      navigate('/dashboard/stations');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  }

  async function getAllState() {
    try {
      const { data } = await baseAPI.getAllState();
      setState(
        data.data.map((item: StateType) => ({
          ...item,
          description: item.name,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function searchCityByState(id: number) {
    try {
      const { data } = await baseAPI.getCityByState(id);

      setCity(
        data.data.map((item: CityType) => ({
          ...item,
          description: item.name,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function GetRetrieverChargers(id: number) {
    try {
      const { data } = await chargersAPI.GetRetrieverChargers(id);
      setValues({...data.data,owner_station_id:data.data.owner_station_id==null ? -1 :data.data.owner_station_id});
      searchCityByState(data.data.address.city.state_id);
    } catch (error) {
      console.error(error);
    }
  }

  async function GetAllOwnerStation(){
    try{
      const { data } = await clientsAPI.GetAllClientsAdmin();
     const OwnerStationData:any[]=data.data.filter((element:any)=>element.is_ownerstation==='Sim');
     OwnerStationData.map((element:any)=>{
      ownerStationOptions.push({id:element.id,description:`Nome:${element.name}, Email :${element.email}`})
     });
     setOwnerStationsOptions([...ownerStationOptions])

    }catch(error){
      toast.error('Erro ao consultar os dados dos donos das estações');
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Title>
        <span>
          <ArrowLeft
            onClick={() => {
              navigate('/dashboard/stations');
            }}
          ></ArrowLeft>
        </span>

        {id === '-1' ? (
          <DashboardBigTitle>
            Cadastro da estação de carregamento
          </DashboardBigTitle>
        ) : (
          <DashboardBigTitle>
            Editar à estação de carregamento - {values.name}
          </DashboardBigTitle>
        )}
      </Title>

      <form onSubmit={handleOnSubmit} action='' method='POST'>
        <Fieldset>
          <LabeledInput
            setValue={(value) => {
              values.name = value;
              setValues({ ...values });
            }}
            value={values.name}
            type='text'
            label='Nome'
            name='name'
          />

          <SelectInput
            setValue={(value) => {
              values.status = value;
              setValues({ ...values });
            }}
            value={values.status}
            label='Status'
            name='status'
            option={status}
          />
        </Fieldset>

        <Fieldset>
          <SelectInput
            setValue={(value) => {
              values.address.city.state_id = value;
              setValues({ ...values });
              searchCityByState(value);
            }}
            value={values.address.city.state_id}
            label='Estado'
            name='state'
            option={state}
          />

          <SelectInput
            setValue={(value) => {
              values.address.city_id = value;
              setValues({ ...values });
            }}
            value={values.address.city_id}
            label='Cidade'
            name='city'
            option={city}
          />
        </Fieldset>

        <Fieldset>
          <LabeledInput
            setValue={(value) => {
              values.address.cep = value;
              setValues({ ...values });
            }}
            value={values.address.cep}
            type='text'
            label='Cep'
            name='cep'
          />

          <LabeledInput
            setValue={(value) => {
              values.address.address = value;
              setValues({ ...values });
            }}
            value={values.address.address}
            type='text'
            label='Endereço'
            name='address'
          />
        </Fieldset>

        {id !== '-1' ? (
            <Fieldset>
            <SelectInput
            setValue={(value) => {
              values.owner_station_id=+value;
              setValues({...values});
            }}
            value={values.owner_station_id}
            label='Dono da Estação'
            name='owner_station'
            option={ownerStationOptions}
          />
          </Fieldset>
          ) : (
            false
          )}



        <Fieldset>
          <div>
            <Button label='Salva' type='submit' primary />
          </div>

          <div>
            <Button
              label='Cancelar'
              type='button'
              onClick={() => {
                navigate('/dashboard/stations');
              }}
            />
          </div>
          {id !== '-1' ? (
            <div>
              <Button
                label='Excluir'
                type='button'
                onClick={() => {
                  openModal();
                }}
              />
            </div>
          ) : (
            false
          )}
        </Fieldset>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ModalHeader>
          <h3>Aviso!</h3>
          <button onClick={() => closeModal()}>
            <CloseModal />
          </button>
        </ModalHeader>

        <BodyModal>
          <p>Deseja mesmo apaga a estacão</p>
          <p>Caso Apaga os dados serão excluído nao tem como recuperar</p>
        </BodyModal>

        <GroupButton>
          <div>
            <Button
              label='Deleta'
              onClick={() => deleteStation(values.id,values.address.id)}
              primary
              type='button'
            />
          </div>
          <div>
            <Button
              label='Cancelar'
              type='button'
              onClick={() => closeModal()}
            />
          </div>
        </GroupButton>
      </Modal>
    </>
  );
};

const BodyModal = styled.header`
  p {
    margin: 1rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

const ModalHeader = styled.header`
  h3 {
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
  }
  display: flex;
  justify-content: center;
  position: relative;
  button {
    right: 0;
    position: absolute;
    cursor: pointer;
    border: none;
    background: transparent;
  }
`;

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    margin: 0.5rem;

    width: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
  }
`;

const Fieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;
  border: none;
  > div {
    margin: 0.5rem;

    width: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
