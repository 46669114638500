import { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';

interface SelectInputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  option: any[];
  name: string;
  label: string;
  setValue?: (arg0: any) => void;
}

export const SelectInput = ({
  option,
  name,
  label,
  ...props
}: SelectInputProps) => {
  return (
    <Wrapper>
      <label htmlFor={name}>{label}</label>
      <Select
        onChange={(event) => props.setValue!(event.target.value)}
        id={name}
        {...props}
      >
        <option value='-1'></option>
        {option.map((item) => (
          <option value={item.id} key={item.id}>
            {item.description}
          </option>
        ))}
      </Select>
    </Wrapper>
  );
};

export const Select = styled.select`
  border: solid 1px #6201d1;
  border-radius: 6px;
  background-color: #f4eaff;
  flex: 1;
  padding: 5px;
  height: 50px;
  width: 100%;
`;

const Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  flex-grow: 1;

  & > label {
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
    size: 24px;
    color: #343434;
  }
`;
