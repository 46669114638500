import { RegisterClientModel } from './../models/Clients/registerclient.model';
import { URLApi } from "../URLApi";
import axios from "axios";

export class ClientsAPI {

  private URL:string;
  private URLApi:URLApi;
  private token:string;

  constructor(){
    this.token = localStorage.getItem('token')!;
    this.URLApi=new URLApi();
    this.URL=this.URLApi.URLRoute();
    
  }


  GetAllClientsAdmin() {
    return axios.get(this.URL+'client/admin/', {
      headers: { Authorization: `Token ${this.token}` },
    });
  }

  RegisterNewClient(registerClient:RegisterClientModel){
    return axios.post(this.URL+"client/register/",registerClient);
}
}
