import React from "react";
import styled from "styled-components";

export const CardBase = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};

const cardWidth = "243px";
const cardHeight = "137px";

const Wrapper = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 15px;
  min-width: ${cardWidth};
  min-height: ${cardHeight};
  width: ${cardWidth};
  height: ${cardHeight};
`;
