import React, { Children } from "react";
import styled from "styled-components";
import { CardBase } from "../card-base/CardBase";

export const IconCard = ({
  icon,
  children,
}: {
  icon: React.ReactElement;
  children: React.ReactNode;
}) => {
  return (
    <CardBase>
      <Wrapper>
        {icon}
        <div id="text-wrapper">{children}</div>
      </Wrapper>
    </CardBase>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  height: fit-content;
  align-self: center;

  div#text-wrapper {
    margin-left: 21px;
  }
`;
