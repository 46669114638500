import styled, { css } from "styled-components";

export const Row = styled.div<{ wrapOn?: string; wrapGap?: string }>`
  display: flex;

  ${(props) => {
    return props.wrapOn
      ? css`
          @media (max-width: ${props.wrapOn}) {
            flex-direction: column;
            align-items: center;
            ${props.wrapGap
              ? css`
                  gap: ${props.wrapGap};
                `
              : null}
          }
        `
      : null;
  }}
`;
