import styled from "styled-components";

interface Props {
  w: string;
  responsive?: boolean;
}
export const HSeparator = ({ w }: Props) => {
  return <Wrapper w={w} />;
};

const Wrapper = styled.div<Props>`
  width: ${(props) => props.w};
`;
