import styled from "styled-components";
import logo from "../../assets/logo-small.png";
import { ReactComponent as Alert } from "../../assets/icons/alert.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { NavLink } from "react-router-dom";
import { useDisplayNavbar } from "../styles/useResize";

const HeaderElement = styled.header`
  background: #f4f4f4;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 8px 3.47%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  img {
    max-height: calc(8vh);
  }
`;

export const Header = (props: { menuCickHanlder?: () => void }) => {
  const displayNavbar = !useDisplayNavbar();
  return (
    <HeaderElement>
      {displayNavbar && (
        <MenuWrapper onClick={props.menuCickHanlder}>
          <Menu />
        </MenuWrapper>
      )}
      <LogoWrapper>
        <NavLink to="/">
          <img src={logo} alt="yellot logo" />
        </NavLink>
      </LogoWrapper>
      <div>
        <NavLink to="notifications">
          <Alert />
        </NavLink>
      </div>
    </HeaderElement>
  );
};

const MenuWrapper = styled.div`
  svg {
    fill: #6201d1;
    width: 30px;
  }
`;
