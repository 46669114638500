import { AxiosResponse } from 'axios';
import { URLApi } from "../URLApi";
import axios from "axios";
import { ChangerStationModel } from '../models/chargers/chargers.model';
import { Response } from '../../types/response';

export type StateType = {
  id: number;
  uf: string;
  name: string;
  geocode: number;
  description?: string;
};

export type CityType = {
  id: number;
  state: number;
  name: string;
  geocode: number;
  description?: string;
};

export class BaseAPI {
  private token: string;
  private URL:string;
  private URLApi:URLApi;

  constructor() {
    this.token = localStorage.getItem('token')!;
    this.URLApi=new URLApi();
    this.URL=this.URLApi.URLRoute();
  }

  OwnerStationList():Promise<Response<ChangerStationModel>> {
    return axios.get(this.URL+'base/ownerstationlist/', {
      headers: { Authorization: `Token ${this.token}` },
    });
  }

  getAllState(): Promise<AxiosResponse<{ data: StateType[] }>> {
    return axios.get(this.URL+'base/state/', {
      headers: { Authorization: `Token ${this.token}` },
    });
  }

  getCityByState(id: number): Promise<AxiosResponse<{ data: CityType[] }>> {
    return axios.get(this.URL+`base/city/?idState=${id}`, {
      headers: { Authorization: `Token ${this.token}` },
    });
  }

  async DeleteOwnerStationRegister(id: number): Promise<Response<unknown>> {
    return await axios.delete(this.URL+`base/ownerstationlist/${id}/`, {
      headers: { Authorization: `Token ${this.token}` },
    });
  }
}
