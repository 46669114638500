import React from "react";
import styled from "styled-components";

interface Props {
  color?: string;
}

export const H1 = styled.h1<Props>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
  color: ${(props) => (props.color ? props.color : "black")};

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const H4 = styled.h1<Props>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${(props) => (props.color ? props.color : "black")};
`;

export const DashboardBigTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => <H1 color="#6201D1">{children}</H1>;

export const DashboardSmallTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => <H4 color="#6201D1">{children}</H4>;
