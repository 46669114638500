import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ReactComponent as SettingsButton } from '../../../assets/icons/dashboard/settings_btn.svg';
import { ReactComponent as AddBtn } from '../../../assets/icons/dashboard/add_btn.svg';

import { KwProvidedChart } from '../../../components/charts/KwhProvidedChart';
import { VSeparator } from '../../../components/separators/Vertical';
import { DataTable, DataTableProps } from '../../../components/table/DataTable';
import { DashboardBigTitle } from '../../../components/typography/Titles';
import {
  ChargersAPI,
} from '../../../shared/apps-backend/ChargersAPI';
import { ChangerStationModel } from '../../../shared/models/chargers/chargers.model';
export const EvStationPage = () => {
  const chargersAPI = new ChargersAPI();
  const navigate = useNavigate();
  let [type_account,setType_account]=useState<string>("");
  let [displayActions,setDisplayAction]=useState<boolean>(false);
  const stationsTable: DataTableProps = {
    columns: ['Id', 'Nome', 'Status', 'Endereço','Id Dono da Estação' ,'Ações'],
    rows: [],
  };

  const [station, setStation] = useState<DataTableProps>(stationsTable);

  useEffect(() => {
    getAllChargers();

  }, []);


  function handleClickEditStation(id: number) {
    navigate(`/dashboard/stations/${id}`);
  }


  async function getAllChargers(): Promise<void> {
    try {
      const { data } = await chargersAPI.GetAllChargers();
      const stationData = data.data.data.map((item: ChangerStationModel) => {

        return [
          <TableTextWrapper>{item.id}</TableTextWrapper>,
          <TableTextWrapper>{item.name}</TableTextWrapper>,
          <TableTextWrapper>{item.address}</TableTextWrapper>,
          <TableTextWrapper>{item.owner_station_id}</TableTextWrapper>,
        ];
      });

      const table = {
        columns: stationsTable.columns,
        rows: stationData,
      } as DataTableProps;

      setStation(table);
    } catch (error) {}
  }

  return (
    <>
      <TitlePage>
        <DashboardBigTitle>Estações de Carregamento</DashboardBigTitle>
        {
          displayActions ? (
            <ButtonAction onClick={() => handleClickEditStation(-1)}>
          <AddBtn></AddBtn>
        </ButtonAction>
          ):''
        }
      </TitlePage>

      <VSeparator h='9.76vh' />
      <DataTable {...station} />
      <VSeparator h='6.10vh' />
      <KwProvidedChart />
      <VSeparator h='6.10vh' />
      <ConfigButton />
    </>
  );
};

type TypePropsAction = {
  id: number;
  status: string;
  display?:boolean;
  handleClickBloquearStation: (
    id: number,
    status: 'active' | 'deactivate' | 'occupied'
  ) => void;
  handleClickEditStation: (id: number) => void;
};


const ConfigButton = () => (
  <ConfigWrapper>
    <SettingsButton />
    <span>Configurações</span>
  </ConfigWrapper>
);

export const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  span {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    svg {
      max-width: 50px;
    }
    span {
      font-size: 1rem;
    }
  }
`;

export const TableTextWrapper = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;

  @media (max-width: 768px) {
    font-size: 0.6rem;
    display: inline-block;
  }
`;

export const ButtonAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 1s;
  :hover {
    filter: brightness(0.7);
  }
`;

export const TitlePage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 3rem;
    height: 3rem;
  }
`;
