import { DashboardBigTitle } from "../../components/typography/Titles";
import { VSeparator } from "../../components/separators/Vertical";
import { DataTable, DataTableProps } from "../../components/table/DataTable";
import styled from "styled-components";
import { ConfigWrapper, TableTextWrapper } from "./EvStationsPage/EvStationsPage";
import { ReactComponent as AddButton } from "../../assets/icons/dashboard/add_btn.svg";
import { ReactComponent as CloseModalBtn } from "../../assets/icons/dashboard/close_modal.svg";
import { useEffect, useState } from "react";
import { Row } from "../../components/styles/Styles";
import {
  LabeledInput,
} from "../../components/labeled-input/LabeledInput";
import { HSeparator } from "../../components/separators/Horizontal";
import { Button } from "../../components/buttons/Button";
import breakpoints from "../../components/styles/Breakpoints";
import { ReactComponent as CancelButton } from "../../assets/icons/dashboard/cancel_btn.svg";
import { LabeledInputDate } from "../../components/labeled-input/LabeledInputDate";
import { LabeledInputFile } from "../../components/labeled-input/LabeledInputFile";
import { AdvertisementsAPI } from "../../shared/apps-backend/AdvertisementsAPI";
import { GetAllAdsModel } from "../../shared/models/advertisements/getallads.model";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Oval } from "react-loader-spinner";

export const AdsPage = () => {
  const RoutesAdvertisementsAPI = new AdvertisementsAPI();
  const [modalVisible, setModalVisible] = useState(false);
  const [showLoaderSpinner, setShowLoaderSpiner] = useState(false);
  const [editingAd, setEditingAd] = useState<AdProps | null>(null);
  const [adsUserData, setAdsUserData] = useState({
    name: "",
    firstDate: "",
    lastDate: "",
    upload: null,
  });

  const [configTable, setConfigTable] = useState([
    {
      rowNumber: 0,
      bgColor: "#5FEE5C",
      color: "white",
    },
  ]);

  const [adsData, setAdsData] = useState<AdProps[]>([
    {
      id: 0,
      startDate: "",
      endDate: "",
      lastView: "",
      title: "",
      totalViews: "",
    },
  ]);

  const adsTable: DataTableProps = {
    columns: [
      "Id",
      "Início",
      "Fim",
      "Nome",
      "Qtd. visualizações",
      "Última visualização",
    ],
    rows: [
      ...adsData.map((ad) => [
      <TableTextWrapper>{ad.id}</TableTextWrapper>,
        <TableTextWrapper>{ad.startDate}</TableTextWrapper>,
        <TableTextWrapper>{ad.endDate}</TableTextWrapper>,
        <TableTextWrapper>{ad.title}</TableTextWrapper>,
        <TableTextWrapper>{ad.totalViews}</TableTextWrapper>,
        <TableTextWrapper>{ad.lastView}</TableTextWrapper>,
      ]),
    ],
    config: configTable,
    defaultBg: "#5FEE5C",
  };

  const [isEditing, setIsEditing] = useState(false);


  const RegisterAds = async () => {
    try {
      setShowLoaderSpiner(true);
      
        let adsformData = new FormData();
        if (
          adsUserData.name !== "" &&
          adsUserData.firstDate !== "" &&
          adsUserData.lastDate !== "" &&
          adsUserData.upload !== null
        ) {
          adsformData.append("name", adsUserData.name);
          adsformData.append("first_date", adsUserData.firstDate);
          adsformData.append("last_date", adsUserData.lastDate);
          adsformData.append("upload", adsUserData.upload!);
          let response = await RoutesAdvertisementsAPI.RegisterAds(
            adsformData
          );
          if (response.data.message) {
            setShowLoaderSpiner(false);
            alert(response.data.message);
            setModalVisible(false);
            setTimeout(() => {
              GetAllAds();
            }, 2000);
          }
        } else {
          alert(
            "Um dos campos não foi preenchido. Preencha todos os campos para salvar o anúncio"
          );
        }
      
    } catch (error) {
      setShowLoaderSpiner(false);
      alert(`Erro ao registrar o anúncio:${error}`);
    }
  };

  const GetAllAds = async () => {
    try {
     
        let response = await RoutesAdvertisementsAPI.GetAllAds();
        let ArrayResponseData: GetAllAdsModel[] = response.data.data;

        let updateAdsDataTable: AdProps[] = [];
        ArrayResponseData.map((element) => {
          updateAdsDataTable.push({
            id: element.id,
            startDate: element.first_date,
            endDate: element.last_date,
            lastView: element.last_visualization,
            title: element.name,
            totalViews: element.amount_visualization?.toString(),
            uploadLink: element.upload,
          });
          return 0;
        });
        setAdsData(updateAdsDataTable);
        let arrayConfigTable: any[] = [];
        let dateToday = new Date();
        updateAdsDataTable.map((ad, index) => {
          let endDate = new Date(ad.endDate);
          arrayConfigTable.push({
            rowNumber: index,
            bgColor: dateToday < endDate ? "#5FEE5C" : "#FF6060",
            color: "white",
          });
          return 0;
        });
        setConfigTable(arrayConfigTable);
      
    } catch (error) {
      alert(`Erro ao consultar os anúncios:${error}`);
    }
  };

  useEffect(() => {
    if (editingAd) setIsEditing(true);
    else setIsEditing(false);
    GetAllAds();
  }, [editingAd]);
  return (
    <>
      {!isEditing && (
        <>
          <DashboardBigTitle>Anúncios</DashboardBigTitle>
          <VSeparator h="4.57vh" />
          <P>
            Esses são os anúncios que irão aparecer no aplicativo dos usuários
            de suas estações de carregamento.
          </P>
          <VSeparator h="11.59vh" />
          <AddBtnWrapper onClick={() => setModalVisible(true)} />
          <Wrapper>
            <DataTable
              {...adsTable}
              onRowClick={(index) => setEditingAd(adsData[index])}
            />
          </Wrapper>
          {modalVisible && (
            <Modal>
              <div className="modal-content">
                <Row style={{ justifyContent: "space-between" }}>
                  <h3>Adicionar anúncio</h3>
                  <CloseModalBtn onClick={() => setModalVisible(false)} />
                </Row>
                <VSeparator h="9.15vh" />

                <LabeledInput
                  label="Nome"
                  name="name"
                  setValue={(name) => {
                    setAdsUserData({ ...adsUserData, name: name });
                  }}
                />
                <VSeparator h="3.05vh" />

                <Row>
                  <LabeledInputDate
                    label="Início"
                    name="start"
                    setValue={(firstDate) => {
                      setAdsUserData({ ...adsUserData, firstDate: firstDate });
                    }}
                  />
                  <HSeparator w="10px" />
                  <LabeledInputDate
                    label="Fim"
                    name="end"
                    setValue={(lastDate) => {
                      setAdsUserData({ ...adsUserData, lastDate: lastDate });
                    }}
                  />
                </Row>
                <VSeparator h="2.29vh" />
                <Box>
                  <ul>
                    <li>
                      Você pode fazer upload de vídeos e imagens de até 25mb nos
                      formatos jpeg, png e jpg ;
                    </li>
                    <li>
                      Após o fim específicado a mídia do anúncio será excluída.
                    </li>
                  </ul>
                </Box>
                <VSeparator h="2.29vh" />

                <LabeledInputFile
                  label="Upload"
                  name="upload"
                  setValue={(upload) => {
                    upload.size <= 25000000
                      ? setAdsUserData({ ...adsUserData, upload: upload })
                      : alert(
                          "Arquivo grande demais. Coloque uma imagem menor do que 25 MB"
                        );
                  }}
                />
                <VSeparator h="2.29vh" />

                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 20,
                  }}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#e6bb3c"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={showLoaderSpinner}
                    ariaLabel="oval-loading"
                    secondaryColor="#e6bb3c"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Row>

                <Row wrapOn={breakpoints.size.sm} wrapGap="6px">
                  <Button
                    label="CANCELAR"
                    onClick={() => setModalVisible(false)}
                  />
                  <HSeparator w="50px" />
                  <Button
                    label="SALVAR"
                    primary
                    onClick={() => RegisterAds()}
                  />
                </Row>
              </div>
            </Modal>
          )}
        </>
      )}
      {isEditing && editingAd && (
        <EditAd
          {...editingAd}
          onCancel={() => setEditingAd(null)}
          onSave={() => {
            setEditingAd(null);
          }}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 80%;
`;

const P = styled.p`
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0px;
  max-width: 85%;

  @media (max-width: 768px) {
    line-height: 3.5vh;
    font-size: 1rem;
  }
`;

const AddBtnWrapper = (props: any) => (
  <ConfigWrapper {...props}>
    <AddButton style={{ cursor: "pointer" }} />
    <span>Adicionar</span>
  </ConfigWrapper>
);

const Modal = styled.div`
  display: flex;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  /* padding-top: 10vh; Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  .modal-content {
    margin: auto;
    padding: 3vw;
    width: 36.32%;

    background: #f4f4f4;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    @media (max-width: 768px) {
      width: 80vw;
      height: 80vh;
      padding: 6.67vw;

      overflow-y: scroll;
    }

    h3 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 400;
      color: #6201d1;
    }
  }
`;

const Box = styled.div`
  background: #fffcf2;
  padding: 8px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: solid 8px #fdc801;

  ul {
    margin-left: 15px;
  }

  li {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

interface AdProps {
  id: number;
  title: string;
  startDate: string;
  endDate: string;
  lastView?: string;
  totalViews?: string;
  uploadLink?: string;
}

interface EditAdActions {
  onSave: () => void;
  onCancel: () => void;
}

const EditAd = ({
  id,
  title,
  startDate,
  endDate,
  lastView,
  totalViews,
  uploadLink,
  onCancel,
  onSave,
}: AdProps & EditAdActions) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [showLoaderSpinner, setShowLoaderSpiner] = useState(false);
  const [updateAdsUserData, setUpdateAdsUserData] = useState({
    name: title,
    firstDate: startDate.split('T')[0],
    lastDate: endDate.split('T')[0],
    upload: null,
  });

  const RoutesAdvertisementsAPI = new AdvertisementsAPI();

  const UpdateAd = async () => {
    try {
      setShowLoaderSpiner(true);
        let adsformData = new FormData();
        if (
          updateAdsUserData.upload !== null
        ) {
          adsformData.append("name", updateAdsUserData.name);
          adsformData.append("first_date", updateAdsUserData.firstDate);
          adsformData.append("last_date", updateAdsUserData.lastDate);
          adsformData.append("upload", updateAdsUserData.upload!);
          let response = await RoutesAdvertisementsAPI.UpdateAds(
            adsformData,
            id
          );
          if (response.data.message) {
            setShowLoaderSpiner(false);
            alert(response.data.message);
            onCancel();
          }
        } else {
          adsformData.append("name", updateAdsUserData.name);
          adsformData.append("first_date", updateAdsUserData.firstDate);
          adsformData.append("last_date", updateAdsUserData.lastDate);
          let response = await RoutesAdvertisementsAPI.UpdateAds(
            adsformData,
            id
          );
          if (response.data.message) {
            setShowLoaderSpiner(false);
            alert(response.data.message);
            onCancel();
          }
        }
      
    } catch (error) {
      setShowLoaderSpiner(false);
      alert(`Erro ao fazer a atualização:${error}`);
    }
  };

  const DeleteAds = async () => {
    try {
      setShowLoaderSpiner(true);
        let response = await RoutesAdvertisementsAPI.DeleteAds(
          id
        );
        if (response.data.message) {
          setShowLoaderSpiner(false);
          alert(response.data.message);
          closeModal();
          onCancel();
        }

    } catch (error) {
      setShowLoaderSpiner(false);
      alert(`Erro ao deletar o anúncio:${error}`);
    }
  };

  return (
    <>
      <DashboardBigTitle>{title}</DashboardBigTitle>
      <VSeparator h="4.57vh" />
      <Row
        style={{
          maxWidth: "43.92vw",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        
      </Row>
      <VSeparator h="4.57vh" />
      <div style={{ maxWidth: "68.81vw" }}>
        <Row>
          <LabeledInput
            name="name"
            label="Nome"
            value={updateAdsUserData.name}
            setValue={(name) => {
              setUpdateAdsUserData({ ...updateAdsUserData, name: name });
            }}
          />
        </Row>
        <HSeparator w="2.93vw" />
        <Row>
          <LabeledInputDate
            name="start"
            label="Início"
            value={updateAdsUserData.firstDate}
            setValue={(firstDate) => {
              setUpdateAdsUserData({
                ...updateAdsUserData,
                firstDate: firstDate,
              });
            }}
          />
          <HSeparator w="2.93vw" />
          <LabeledInputDate
            name="end"
            label="Fim"
            value={updateAdsUserData.lastDate}
            setValue={(lastDate) => {
              setUpdateAdsUserData({
                ...updateAdsUserData,
                lastDate: lastDate,
              });
            }}
          />
        </Row>
        <VSeparator h="4.57vh" />

        <Box>
          <ul>
            <li>
              Você pode fazer upload de vídeos e imagens de até 25mb nos
              formatos jpeg, png e jpg;
            </li>
            <li>Após o fim específicado a mídia do anúncio será excluída.</li>
          </ul>
        </Box>
        <VSeparator h="4.57vh" />
        <img src={`data:image/*;base64,${uploadLink}`} alt="Upload Image" />
        <Row>
          <LabeledInputFile
            label="UPLOAD"
            name="upload"
            setValue={(upload) => {
              upload.size <= 25000000
                ? setUpdateAdsUserData({ ...updateAdsUserData, upload: upload })
                : alert(
                    "Arquivo grande demais. Coloque uma imagem menor do que 25 MB"
                  );
            }}
          />
        </Row>
        <VSeparator h="4.57vh" />
        <Row>
          <RemoveButton />
          <HSeparator w="50px" />
          <ConfigWrapper>
            <CancelButton
              style={{ cursor: "pointer" }}
              onClick={() => setOpen((o) => !o)}
            />
            <span>Excluir</span>
          </ConfigWrapper>
          <Popup
            contentStyle={{ padding: 50 }}
            open={open}
            closeOnDocumentClick
            onClose={closeModal}
          >
            <h3 style={{ textAlign: "center", padding: 20 }}>
              Você tem certeza que deseja excluir esse anúncio?
            </h3>

            <Row
              style={{ display: "flex", justifyContent: "center", padding: 20 }}
            >
              <Oval
                height={80}
                width={80}
                color="#e6bb3c"
                wrapperStyle={{}}
                wrapperClass=""
                visible={showLoaderSpinner}
                ariaLabel="oval-loading"
                secondaryColor="#e6bb3c"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </Row>

            <Row>
              <Button label="NÃO" onClick={closeModal} />
              <HSeparator w="50px" />
              <Button label="SIM" primary onClick={DeleteAds} />
            </Row>
          </Popup>
        </Row>
      </div>
      <VSeparator h="4.57vh" />
      <Row style={{ display: "flex", justifyContent: "center", padding: 20 }}>
        <Oval
          height={80}
          width={80}
          color="#e6bb3c"
          wrapperStyle={{}}
          wrapperClass=""
          visible={showLoaderSpinner}
          ariaLabel="oval-loading"
          secondaryColor="#e6bb3c"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </Row>

      <Row
        wrapOn={breakpoints.size.sm}
        wrapGap="6px"
        style={{ maxWidth: "31.48vw" }}
      >
        <Button label="CANCELAR" onClick={onCancel} />
        <HSeparator w="50px" />
        <Button label="SALVAR" primary onClick={UpdateAd} />
      </Row>
    </>
  );
};

const AdTextData = (props: { label: string; data?: string }) => {
  return (
    <AdTextWrapper>
      <span>{props.label}</span>
      <span>{props.data}</span>
    </AdTextWrapper>
  );
};

const AdTextWrapper = styled.div`
  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: block;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 1.5;
    }
  }

  @media (max-width: 768px) {
    span:first-of-type {
      font-weight: bold;
    }
  }
`;

const RemoveButton = (props: any) => (
  <ConfigWrapper {...props}>
    <CancelButton
      onClick={() => {
        alert("Função ainda não implementada");
      }}
      style={{ cursor: "pointer" }}
    />
    <span>Desativar</span>
  </ConfigWrapper>
);
