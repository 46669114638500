import { AxiosResponse } from 'axios';
import { Response } from '../../types/response';
import { URLApi } from "../URLApi";
import axios from "axios";
import { ChangerStationModel, CreateChangerStationModel, StationRetrieverModel } from '../models/chargers/chargers.model';
export class ChargersAPI {
  private token: string;
  private URL:string;
  private URLApi:URLApi;

  constructor() {
    this.token = localStorage.getItem('token')!;
    this.URLApi=new URLApi();
    this.URL=this.URLApi.URLRoute();
  }

  async updatePartialChargers(
    id: number,
    data: { status: 'active' | 'deactivate' | 'occupied' }
  ): Promise<Response<ChangerStationModel>> {
    return await axios.patch(this.URL+`chargepoint/api/chargepointadmin/${id}/`, data, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
  }

  async CreateChargers(
    data: CreateChangerStationModel | StationRetrieverModel
  ): Promise<Response<unknown>> {
    return await axios.post(this.URL+`chargepoint/api/chargepointadmin/`, data, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
  }

  async UpdateChargers(
    data: CreateChangerStationModel | StationRetrieverModel
  ): Promise<Response<unknown>> {
    return await axios.put(this.URL+`chargepoint/api/chargepointadmin/${data.id}/`, data, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
  }

  async DeleteChargers(id: number): Promise<Response<unknown>> {
    return await axios.delete(this.URL+`chargepoint/api/chargepointadmin/${id}/`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
  }

  async GetAllChargers(): Promise<Response<ChangerStationModel>> {
    return await axios.get(this.URL+'chargepoint/api/chargepointadmin/', {
      headers: { Authorization: `Bearer ${this.token}` },
    });
  }

  async GetRetrieverChargers(
    id: number
  ): Promise<AxiosResponse<{ data: StationRetrieverModel }>> {
    return await axios.get(this.URL+`chargepoint/api/chargepointadmin/${id}/`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
  }

  GetAllCommunicationsLogs(token: string) {
    return axios.get(this.URL+'charger/logs/', {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

