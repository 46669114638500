import styled from "styled-components";

interface Props {
  h: string;
}
export const VSeparator = ({ h }: Props) => {
  return <Wrapper h={h} />;
};

const Wrapper = styled.div<Props>`
  height: ${(props) => props.h};
`;
