import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import styled from "styled-components";
import { CardBase } from "../card-base/CardBase";
import "react-circular-progressbar/dist/styles.css";

export const StationsInUseCard = ({
  total,
  inUse,
}: {
  total: number;
  inUse: number;
}) => {
  return (
    <CardBase>
      <Wrapper>
        <span>Estações em uso</span>
        <Row>
          <CircularProgressWrapper>
            <CircularProgressbar
              value={inUse}
              minValue={0}
              maxValue={total}
              strokeWidth={20}
              styles={{
                path: {
                  strokeLinecap: "butt",
                  stroke: "#5FEE5C",
                },
                trail: {
                  stroke: "#C2C2C2",
                },
              }}
            />
          </CircularProgressWrapper>
          <div id="info">
            <span>{total} estações</span>
            <div id="legend-wrapper">
              <div id="legend"></div>
              <span>{inUse} estações em uso</span>
            </div>
          </div>
        </Row>
      </Wrapper>
    </CardBase>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > span {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    color: #1a1a1a;
    margin-bottom: 13px;
  }
`;

const CircularProgressWrapper = styled.div`
  width: 70px;
  height: 70px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;

  div#info {
    margin-left: 24px;
  }

  span {
    font-family: "Poppins";
    font-size: 11px;
    font-weight: 500;
  }

  div#legend {
    background-color: #5fee5c;
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  div#legend-wrapper {
    display: flex;
    margin-top: 5px;
  }
`;
