import {
    LoginModel,
    LoginResponse,
} from '../models/authentication/login.model';
import {URLApi} from "../URLApi";
import axios from "axios";
import { api } from '../../services/axios';

type ForgotPassword = {
    uidb64: string;
    token: string;
    new_password: string;
};

export class AuthenticationAPI {

    private URL: string;
    private URLApi: URLApi;

    constructor() {
        this.URLApi = new URLApi();
        this.URL = this.URLApi.URLRoute();
    }


    async Login(loginData: LoginModel): Promise<{ data: LoginResponse }> {
        return await axios.post(this.URL + 'api/token/', loginData);
    }

    forgotPassword(data: ForgotPassword): Promise<{
        data: {
            data: {
                message: string;
            };
        };
    }> {
        return api.patch(`forget-password/update-password/${data.uidb64}/`, data);
    }
}
